import React from "react";
import {
  Grid,
  Container,
  TextField,
  Typography,
  LinearProgress,
  Box,
  Paper,
  Button,
  Stack,
  Divider,
  Autocomplete,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  FormGroup,
  RadioGroup,
  Radio,
  Menu,
  useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import countries from "./countries.json";
import { IMAGES_API_URL } from "./constants";
import LogoPng from "./Assets/eventegration.png";
// import HowToRegIcon from "@mui/icons-material/HowToReg";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { RegForm } from "./RegistrationPage";
import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useFeatures } from "./Hooks/GetFeatures";
import LinkNotFound from "./Components/LinkNotFound";

export default function SurveyForm() {
  const { link } = useParams();
  const { data, loading, error } = useQuery(GET_REG_STRUCTURE, {
    variables: { link },
  });
  const responseBoxRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [registrationId, setRegistrationId] = React.useState(null);

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [response, setResponse] = React.useState("");

  const { featureData, featureLoading } = useFeatures(data?.reg?.eventId, data?.reg?.role);

  const url = encodeURIComponent(window.location.href);
  const title = encodeURIComponent(data?.reg?.eventName || "Event");
  const linkText = "Check it out here";
  const image = `${IMAGES_API_URL}banner/${data?.reg?.eventId}.jpeg`;
  let parsedShareContent = data?.reg?.shareContent ? JSON.parse(data?.reg?.shareContent) || null : null;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  let featuresList = []
  if (featureData && featureData?.getFeatureList) {
    featuresList = JSON.parse(featureData?.getFeatureList.features)
  }
  // console.log(featuresList);
  let description = null
  let encodedDescription = null
  let urlRegex = null
  let foundUrls = null
  let extractedUrl = null
  let twitterShareLink = null
  let linkedinShareLink = null
  let whatsappShareLink = null
  let fbShareLink = null

  if (featuresList.includes('ShareButton')) {
    description = parsedShareContent;
    encodedDescription = encodeURIComponent(description);
    urlRegex = /(https?:\/\/[^\s]+)/g;
    foundUrls = description?.match(urlRegex);

    extractedUrl = foundUrls ? foundUrls[0] : null;

    twitterShareLink = `https://twitter.com/intent/tweet?text=${encodedDescription}`;
    linkedinShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${extractedUrl}`;
    whatsappShareLink = `https://api.whatsapp.com/send?text=${title} - ${encodedDescription}`;
    fbShareLink = `https://www.facebook.com/sharer/sharer.php?u=${extractedUrl}&quote=${encodedDescription}&picture=${image}`;
  }
  const handleReset = () => {
    setResponse("");
  };

  React.useEffect(() => {
    responseBoxRef?.current?.scrollIntoView();
  }, [response]);
  if (loading) {
    return <LinearProgress />;
  }
  if (!data?.reg?.eventId) {
    return <LinkNotFound />;
  }

  if (error) {
    return (
      <Container>
        <Typography color="error" variant="h4">
          {error?.message}
          {/* <pre>{JSON.stringify(error, null, 2)}</pre> */}
        </Typography>
      </Container>
    );
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ py: 3 }}>
        <img
          src={`${IMAGES_API_URL}banner/${data.reg.eventId}.jpeg`}
          width="100%"
          alt="Banner"
        /> 
        <Box p={3} />
        {/* <Typography
          variant="h2"
          gutterBottom
          align="center"
          sx={{ textTransform: "capitalize" }}
        >
          {data.reg.eventName}
        </Typography> */}
        {!response && (
          <>
            {!!data.reg.formHeading ? (
              <Typography variant="h4" gutterBottom align="center">
                {data.reg.formHeading}
              </Typography>
            ) : (
              <Typography
                variant="h4"
                gutterBottom
                align="center"
                sx={{ textTransform: "capitalize" }}
              >
                {data.reg.role} Registration
              </Typography>
            )}
          </>
        )}
        <Box sx={{ p: { xs: 1, md: 2 } }}>
          {response ? (
            <Stack spacing={1} ref={responseBoxRef}>
              <Typography>
                <div
                  dangerouslySetInnerHTML={{ __html: response }}
                  style={{ whiteSpace: "pre-line" }}
                />
              </Typography>
              {/* <Box height={30} />*/}
              {!featuresList.includes('ShareButton') ? "" :
                <Stack direction={"row"} justifyContent={"center"} flexWrap={"wrap"} divider={<Divider orientation={isSmallScreen ? "horizontal" : "vertical"} flexItem />} spacing={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        startIcon={<FacebookIcon />}
                        onClick={handleClose}
                        component="a"
                        href={fbShareLink}
                        variant="contained"
                        target="_blank"
                        size="large"
                        rel="noopener noreferrer"
                        sx={{ backgroundColor: '#1877F2', color: 'white', '&:hover': { backgroundColor: '#145db2' } }}
                      >
                        Share on Facebook
                      </Button>
                    </Grid>

                    <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        startIcon={<TwitterIcon />}
                        onClick={handleClose}
                        component="a"
                        href={twitterShareLink}
                        target="_blank"
                        size="large"
                        rel="noopener noreferrer"
                        sx={{ backgroundColor: '#1DA1F2', color: 'white', '&:hover': { backgroundColor: '#1a8acb' } }}
                      >
                        Share on Twitter
                      </Button>
                    </Grid>

                    <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        startIcon={<LinkedInIcon />}
                        onClick={handleClose}
                        component="a"
                        href={linkedinShareLink}
                        target="_blank"
                        size="large"
                        rel="noopener noreferrer"
                        sx={{ backgroundColor: '#0A66C2', color: 'white', '&:hover': { backgroundColor: '#084a82' } }}
                      >
                        Share on LinkedIn
                      </Button>
                    </Grid>

                    <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        startIcon={<WhatsAppIcon />}
                        onClick={handleClose}
                        component="a"
                        href={whatsappShareLink}
                        target="_blank"
                        size="large"
                        rel="noopener noreferrer"
                        sx={{ backgroundColor: '#25D366', color: 'white', '&:hover': { backgroundColor: '#1ca851' } }}
                      >
                        Share on WhatsApp
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              }
              {/*   <Button
                  onClick={handleReset}
                  variant="contained"
                  color="error"
                  size="small"
                >
                  Another registration?
                </Button>
              </Box> */}
            </Stack>
          ) : !registrationId? <FindRegistration setRegistrationId = {setRegistrationId} eventId={data?.reg?.eventId} /> : (
            <RegForm
              registrationId={registrationId}
              fields={data.reg.fields}
              onSubmit={(res) => setResponse(res)}
              eventId={data.reg.eventId}
              role={data.reg.role}
              featuresList={featuresList}
            />
          )}
        </Box>
        <Box
          sx={{
            py: 6,
            display: "flex",
            justifyContent: { xs: "center", md: "right" },
          }}
        >
          <Box>
            <Typography color="text.secondary" fontSize={12}>
              Powered by
            </Typography>
            <a href="http://eventegration.com">
              <img src={LogoPng} width={180} />
            </a>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}


export function FindRegistration({ setRegistrationId, eventId }) {
    const { control, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const [searchParams, setSearchParams] = React.useState({ email: '', id: '', eventId });
    const [formSubmitted, setFormSubmitted] = React.useState(false);
  
    const { loading, error, data } = useQuery(GET_REGISTRATION_DATA, {
      variables: searchParams,
      skip: !formSubmitted, // Skip query if form has not been submitted
    });
  
    const onSubmit = (data) => {
      setSearchParams({ ...data, eventId });
      setFormSubmitted(true);
    };
  
    const emailValue = watch('email');
    const idValue = watch('id');
  
    const handleInputChange = (field) => {
      if (field === 'email' && emailValue) {
        reset({ id: '' });
      }
      if (field === 'id' && idValue) {
        reset({ email: '' });
      }
    };

    if(data && data.getRegistrationId != null)(
        setRegistrationId(data.getRegistrationId)
    )
  
    return (
      <Box>
        <Typography textAlign="center" my={2} variant="h5" gutterBottom>
          Enter your registered email or Registration ID to begin the survey
        </Typography>
        <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={2}>
              <Controller
                name="email"
                control={control}
                rules={{ pattern: /^\S+@\S+$/i }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Email"
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange('email');
                    }}
                    disabled={!!idValue}
                    error={!!errors.email}
                    helperText={errors.email && 'Please enter a valid email address'}
                  />
                )}
              />
            </Box>
            <Divider>OR</Divider>
            <Box mb={2} mt={2}>
              <Controller
                name="id"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Registration ID"
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange('id');
                    }}
                    disabled={!!emailValue}
                    error={!!errors.id}
                    helperText={errors.id && 'Please enter a valid ID'}
                  />
                )}
              />
            </Box>
            <Button type="submit" variant="contained" color="secondary" fullWidth>
              Start Survey
            </Button>
          </form>
          {loading && <p>Loading...</p>}
          {error && <p>{error.message}</p>}
          {data && data.getRegistrationId === null && (
            <Typography variant="body1" color={"red"} mt={2}>
Sorry, no user found with the given details. Please register first to fill out the survey form or enter correct details.            </Typography>
          )}
        </Box>
      </Box>
    );
  }

const GET_REGISTRATION_DATA = gql`
  query GetRegistrationData($email: String, $id: String, $eventId:String!) {
    getRegistrationId(email: $email, id: $id, eventId:$eventId) 
  }
`;

const GET_REG_STRUCTURE = gql`
  query ($link: String!) {
    reg(link: $link) {
      eventId
      eventName
      role
      formHeading
      shareContent
      fields {
        name
        type
        label
        sx
        xs
        md
        placeholder
        options
        defaultValue
        minLength
        maxLength
        pattern
        required
        readonly
      }
    }
  }
`;
