import React, { useState } from "react";
import {
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  Tabs,
  Tab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PreviewCard, { PreviewCard3 } from "./PreviewCard";

export const PreviewAgenda3 = ({  agendaDetails,selectedDay }) => {
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  // Sort agenda details based on start time
  
  return (
    <Box container spacing={0}>
          {agendaDetails && agendaDetails[selectedDay]?.map((item, index) => (
            <Grid item key={index} xs={12}>
              {item.status ==='PUBLISH' && <PreviewCard3 previewData={item} />}
            </Grid>
          ))}
        </Box>
  );
};
