import {
  Box,
  Divider,
  Grid,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { formatTime } from "../../constants";



export default function PreviewCard({ previewData }) {
  

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const theme = useTheme();
  const color = getColor(previewData?.agendaType);

  

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  
  const speakerNames = previewData?.speaker.split(",");
  const cleanedDescription = previewData?.presentationDescription
    ? previewData?.presentationDescription.replace(/^"|"$/g, "")
    : "";

  function getColor(type) {
    const colorMap = {
      session: "#fff",
      break: "#fff",
      lunch: "#fff",
    };

    return colorMap[type] || "#000";
  }
  
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "center",
          alignItems: "center",
          margin: 2,
          padding: 3,
          border: "1px solid #ccc",
          borderRadius: "15px",
          marginBottom: 3,
          color: color,

          // backgroundColor: backgroundColor,
          backgroundColor:
            previewData?.agendaType === "session"
              ? "#E74C3C"
              : previewData?.agendaType === "break"
              ? "#27AE60"
              : previewData?.agendaType === "lunch"
              ? "#2C3E50"
              : "#fff",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            padding: 2,
            marginX: 2,
            width: isSmallScreen ? "100%" : "35%",
            textAlign: isSmallScreen ? "start" : "center",
            flexShrink: 0,
          }}
        >
          <Stack direction="row" justifyContent={"center"} spacing={2}>
            <Typography fontSize={"1.5rem"} fontWeight={"bold"}>
              {`${formatTime(previewData?.startTime)} - ${formatTime(
                previewData?.endTime
              )}`}
            </Typography>
          </Stack>
        </Box>
        <Divider
          orientation={isSmallScreen ? "horizontal" : "vertical"}
          flexItem
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: isSmallScreen ? "100%" : "45%",
            marginX: 2,
            padding: 2,
            [theme.breakpoints.up("sm")]: {
              width: "100%",
            },
            flexShrink: 1,
          }}
        >
          {previewData?.sessionTitle && (
            <Typography fontWeight={600} fontSize={"1.5rem"}>
              Title: &nbsp;{previewData?.sessionTitle}
            </Typography>
          )}
          {previewData?.agendaType && (
            <Typography>Type: &nbsp;{previewData?.agendaType}</Typography>
          )}

          {previewData?.presentationDescription && (
            <Typography sx={{ maxWidth: "60%", display: "flex" }}>
              &nbsp;
              <span dangerouslySetInnerHTML={{ __html: cleanedDescription }} />
            </Typography>
          )}
          {speakerNames.length > 1 ? (
            <Typography>
              <Typography component="span" fontWeight="bold">
                Speakers
              </Typography>
              : &nbsp;
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  marginLeft: "60px",
                }}
              >
                {speakerNames.map((speaker, id) => (
                  <li key={id}>{speaker}</li>
                ))}
              </ul>
            </Typography>
          ) : (
            <Typography>
              <Typography component="span" fontWeight="bold">
                Speakers
              </Typography>
              : &nbsp;{speakerNames[0]}
            </Typography>
          )}
        </Box>
      </Grid>
    </>
  );
}

export function PreviewCard2({ previewData }) {
  // if (previewData?.status !== 'PUBLISH') {
  //   return null;
  // }

  const theme = useTheme();
  const color = getColor(previewData?.agendaType);
  // const { eventId } = useGetMe();
  // const speakerName = useGetSpeakerName(eventId, previewData?.speaker);
  const speakerNames = previewData?.speaker.split(",");
  const cleanedDescription = previewData?.presentationDescription
    ? previewData?.presentationDescription.replace(/^"|"$/g, "")
    : "";

  function getColor(type) {
    const colorMap = {
      session: "#fff",
      break: "#fff",
      lunch: "#fff",
    };

    return colorMap[type] || "#000";
  }

  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" sx={{ backgroundColor: color }}>
          <Typography fontWeight="bold">
            {`${formatTime(previewData?.startTime)} - ${formatTime(
              previewData?.endTime
            )}`}
          </Typography>
        </TableCell>
        <TableCell align="center">{previewData?.sessionTitle}</TableCell>
        <TableCell align="center">{previewData?.agendaType}</TableCell>
        <TableCell align="center">{speakerNames[0]}</TableCell>
        <TableCell align="center">
          <span dangerouslySetInnerHTML={{ __html: cleanedDescription }} />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

export function PreviewCard3({ previewData }) {
 
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const theme = useTheme();
  const color = getColor(previewData?.agendaType);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  

  // const { eventId } = useGetMe();
  const speakerNames = previewData?.speaker.split(",");
  const cleanedDescription = previewData?.presentationDescription
    ? previewData?.presentationDescription.replace(/^"|"$/g, "")
    : "";

  function getColor(type) {
    const colorMap = {
      session: "gray",
      break: "#eeeee4",
      lunch: "#eeeee4",
    };

    return colorMap[type] || "#000";
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // margin: 2,
          padding: 3,
          border: "1px solid #ccc",
          // borderRadius: "15px",
          // marginBottom: 3,
          color: color,
          backgroundColor: "#fff",
          // previewData?.agendaType === "session"
          //   ? "#E74C3C"
          //   : previewData?.agendaType === "break"
          //   ? "#27AE60"
          //   : previewData?.agendaType === "lunch"
          //   ? "#2C3E50"
          //   : "#fff",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            padding: 2,
            width: "35%",
            textAlign: "start",
          }}
        >
          <Typography fontSize={"1.5rem"} fontWeight={"bold"}>
            {`${formatTime(previewData?.startTime)} - ${formatTime(
              previewData?.endTime
            )}`}
          </Typography>
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ height: "auto", color: "#000" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "60%",
            padding: 2,
          }}
        >
          {previewData?.sessionTitle && (
            <Typography
              fontWeight={600}
              fontSize={"1.5rem"}
              sx={{ color: "#1e81b0" }}
            >
              &nbsp;{previewData?.sessionTitle}
            </Typography>
          )}
          {/* {previewData?.agendaType && (
            <Typography>Type: &nbsp;{previewData?.agendaType}</Typography>
          )} */}

          {previewData?.presentationDescription && (
            <Typography sx={{ maxWidth: "100%", display: "flex" }}>
              &nbsp;
              <span dangerouslySetInnerHTML={{ __html: cleanedDescription }} />
            </Typography>
          )}
          {speakerNames?.length > 1 ? (
            <Typography>
              <Typography component="span" fontWeight="bold">
                Speakers
              </Typography>
              : &nbsp;
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  marginLeft: "60px",
                }}
              >
                {speakerNames.map((speaker, id) => (
                  <li key={id}>{speaker}</li>
                ))}
              </ul>
            </Typography>
          ) : (
            <Typography>
              <Typography component="span" fontWeight="bold">
                Speakers
              </Typography>
              : &nbsp;{speakerNames[0]}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}
