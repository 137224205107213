import React, { useState } from "react";
import {
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PreviewCard, { PreviewCard2 } from "./PreviewCard";

export const PreviewAgenda2 = ({ agendaDetails,selectedDay }) => {
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  

  return (
        <Grid container spacing={2}>
          <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Time</TableCell>
                  <TableCell align="center">Title</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Speaker</TableCell>
                  <TableCell align="center">Description</TableCell>
                </TableRow>
              </TableHead>
              {agendaDetails[selectedDay].map((item, index) => (
                <PreviewCard2 key={index} previewData={item} />
              ))}
            </Table>
          </TableContainer>
        </Grid>
  );
};
