import React, { useState } from "react";
import {
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  Tabs,
  Tab,
} from "@mui/material";
import PreviewCard from "./PreviewCard";

const PreviewAgenda = ({  agendaDetails,selectedDay }) => {
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  

  return (
        <Grid container spacing={2} >
          {agendaDetails[selectedDay].map((item, index) => (
            <PreviewCard key={index} previewData={item}  />
          ))}
        </Grid>
    
  );
};

export default PreviewAgenda;

