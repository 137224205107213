import React from "react";
import PreviewSection from "./Components/agendaPreview/PreviewSection";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

const GET_AGENDA_DETAILS = gql`
  query GetAgendaDetails($eventId: String, $id: Int) {
    getAgendaDetails(eventId: $eventId, id: $id)
  }
`;

const GET_AGENDA = gql`
  query GetAgendaByDefaultTheme($link: String!) {
    getAgendaFromLink(link: $link)
  }
`;
export default function AgendaPreview() {
  const params = useParams();
  const { link } = params;
  console.log("link", link);

  const { loading, error, data } = useQuery(GET_AGENDA, {
    variables: { link },
  });

  const agendaData = data ? JSON.parse(data?.getAgendaFromLink) : null;
  console.log("dataaaaaaaaaa", agendaData);

  const {
    data: agendaDetailsData,
    loading: agendaDetailsLoading,
    error: agendaDetailsError,
    refetch: agendaDetailRefetch,
  } = useQuery(GET_AGENDA_DETAILS, {
    variables: {
      eventId: agendaData?.eventId,
      id: agendaData?.id,
    },
  });

  let agendaDetails;
  if (agendaDetailsData && agendaDetailsData.getAgendaDetails)
    agendaDetails = JSON.parse(agendaDetailsData.getAgendaDetails);

  console.log("agenda details data", agendaDetails);

  const startDate = new Date(agendaData?.startDate);
  const endDate = new Date(agendaData?.endDate);
  const dateDifference = Math.ceil(
    Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24) + 1
  );
  const days = [];
  for (let i = 1; i <= dateDifference; i++) {
    days.push(i);
  }

  const segregatedData = [];
  days.map((day) => {
    const filteredAgendas = agendaDetails?.filter(
      (agenda) => agenda.day === day
    );
    segregatedData.push(filteredAgendas || []);
  });
  return (
    <div>
      <PreviewSection
        selectedPreview={agendaData?.defaultTheme}
        agendaDetails={segregatedData}
      />
    </div>
  );
}
