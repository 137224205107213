import { gql, useQuery } from '@apollo/client';

export function useFeatures(eventId, role) {
  const { data: featureData, loading: featureLoading } = useQuery(GET_VERIFICATION_FEATURE, {
    variables: { eventId, role },
  });

  return {
    featureData,
    featureLoading,
  };
}

export const GET_VERIFICATION_FEATURE = gql`
  query ($eventId: String!, $role: ROLE!) {
    getFeatureList(eventId: $eventId, role: $role) {
      features
    }
  }
`;
