import React from "react";
import {
  Grid,
  Container,
  TextField,
  Typography,
  LinearProgress,
  Box,
  Paper,
  Button,
  Stack,
  Divider,
  Autocomplete,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  FormGroup,
  RadioGroup,
  Radio,
  Menu,
  useMediaQuery,
  InputAdornment,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import countries from "./countries.json";
import { IMAGES_API_URL } from "./constants";
import LogoPng from "./Assets/eventegration.png";
// import HowToRegIcon from "@mui/icons-material/HowToReg";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ShareIcon from "@mui/icons-material/Share";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SearchModal from "./Components/SearchModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useEffect } from "react";
import LinkNotFound from "./Components/LinkNotFound";
import NoUserFound from "./Components/NoUserFound";

export default function EditRegistration() {
  const { link, id } = useParams();
  const location= useLocation();
  const [iframeTextFound, setIframeTextFound] = React.useState(false);
  React.useEffect(() => {
    if (location.pathname.includes('iframe')) {
      setIframeTextFound(true);
    } else {
      setIframeTextFound(false);
    }
  }, [location.pathname]);
  const { data, loading, error } = useQuery(GET_REG_STRUCTURE, {
    variables: { link },
  });
  // Determine whether to skip fetching user details
  const skipUserDetails = !id || !data?.reg?.role || !data?.reg?.eventId;
  const getUserDetailVariables = {
    eventId: data?.reg?.eventId,
    role: data?.reg?.role,
  };

  if (id) {
    getUserDetailVariables.id = id;
  }

  const {
    data: userDetailData,
    loading: userDetailLoading,
    error: userDetailError,
  } = useQuery(GET_USER_DETAIL, {
    variables: getUserDetailVariables,
    skip: skipUserDetails,
  });
  console.log(link);
  const responseBoxRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [response, setResponse] = React.useState("");

  const url = encodeURIComponent(window.location.href);
  const title = encodeURIComponent(data?.reg?.eventName || "Event");
  const linkText = "Check it out here";
  const image = `${IMAGES_API_URL}banner/${data?.reg?.eventId}.jpeg`;
  let parsedShareContent = data?.reg?.shareContent
    ? JSON.parse(data?.reg?.shareContent)
    : null;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const description =
    parsedShareContent ||
    `I am visiting the ${data?.reg?.eventName} event. Join me! ${linkText}:  ${url}`;
  const encodedDescription = encodeURIComponent(description);
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  // console.log(description);
  const foundUrls =
    Array.isArray(description) && description.length > 0
      ? description.match(urlRegex)
      : "";

  const extractedUrl = foundUrls ? foundUrls[0] : null;

  const twitterShareLink = `https://twitter.com/intent/tweet?text=${encodedDescription}`;
  const linkedinShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${extractedUrl}`;
  const whatsappShareLink = `https://api.whatsapp.com/send?text=${title} - ${encodedDescription}`;
  const fbShareLink = `https://www.facebook.com/sharer/sharer.php?u=${extractedUrl}&quote=${encodedDescription}&picture=${image}`;

  const handleReset = () => {
    setResponse("");
  };

  React.useEffect(() => {
    responseBoxRef?.current?.scrollIntoView();
  }, [response]);
  console.log("?>>>>>>>>", data);

  console.log("notuserDetail",userDetailError?.message);
  if (loading || userDetailLoading) {
    return <LinearProgress />;
  }
  if (userDetailError && userDetailError?.message === "User does not exists") {
    return <NoUserFound iframe={iframeTextFound} link={link}/>;
  }
  if (!userDetailData) {
    // console.log("notuserDetail",userDetailError.message);
    return <LinkNotFound />;
  }
  if (!data?.reg?.eventId) {
    return <LinkNotFound />;
  }

  if (error) {
    return (
      <Container>
        <Typography color="error" variant="h4">
          {error?.message}
          {/* <pre>{JSON.stringify(error, null, 2)}</pre> */}
        </Typography>
      </Container>
    );
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ py: 3 }}>
        <img
          src={`${IMAGES_API_URL}banner/${data.reg.eventId}.jpeg`}
          width="100%"
        />
        <Box p={3} />
        {/* <Typography
          variant="h2"
          gutterBottom
          align="center"
          sx={{ textTransform: "capitalize" }}
        >
          {data.reg.eventName}
        </Typography> */}
        {!response && (
          <>
            {!!data.reg.formHeading ? (
              <Typography variant="h4" gutterBottom align="center">
                {data.reg.formHeading}
              </Typography>
            ) : (
              <Typography
                variant="h4"
                gutterBottom
                align="center"
                sx={{ textTransform: "capitalize" }}
              >
                {data.reg.role} Registration
              </Typography>
            )}
          </>
        )}
        <Box sx={{ p: { xs: 1, md: 2 } }}>
          {response ? (
            <Stack spacing={1} ref={responseBoxRef}>
              <Typography>
                <div
                  dangerouslySetInnerHTML={{ __html: response }}
                  style={{ whiteSpace: "pre-line" }}
                />
              </Typography>
              {/* <Box height={30} />*/}
              {!parsedShareContent ? (
                ""
              ) : (
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  flexWrap={"wrap"}
                  divider={
                    <Divider
                      orientation={isSmallScreen ? "horizontal" : "vertical"}
                      flexItem
                    />
                  }
                  spacing={2}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        startIcon={<FacebookIcon />}
                        onClick={handleClose}
                        component="a"
                        href={fbShareLink}
                        variant="contained"
                        target="_blank"
                        size="large"
                        rel="noopener noreferrer"
                        sx={{
                          backgroundColor: "#1877F2",
                          color: "white",
                          "&:hover": { backgroundColor: "#145db2" },
                        }}
                      >
                        Share on Facebook
                      </Button>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        startIcon={<TwitterIcon />}
                        onClick={handleClose}
                        component="a"
                        href={twitterShareLink}
                        target="_blank"
                        size="large"
                        rel="noopener noreferrer"
                        sx={{
                          backgroundColor: "#1DA1F2",
                          color: "white",
                          "&:hover": { backgroundColor: "#1a8acb" },
                        }}
                      >
                        Share on Twitter
                      </Button>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        startIcon={<LinkedInIcon />}
                        onClick={handleClose}
                        component="a"
                        href={linkedinShareLink}
                        target="_blank"
                        size="large"
                        rel="noopener noreferrer"
                        sx={{
                          backgroundColor: "#0A66C2",
                          color: "white",
                          "&:hover": { backgroundColor: "#084a82" },
                        }}
                      >
                        Share on LinkedIn
                      </Button>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        startIcon={<WhatsAppIcon />}
                        onClick={handleClose}
                        component="a"
                        href={whatsappShareLink}
                        target="_blank"
                        size="large"
                        rel="noopener noreferrer"
                        sx={{
                          backgroundColor: "#25D366",
                          color: "white",
                          "&:hover": { backgroundColor: "#1ca851" },
                        }}
                      >
                        Share on WhatsApp
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              )}
              {/* <Button
                  onClick={handleReset}
                  variant="contained"
                  color="error"
                  size="small"
                >
                  Another registration?
                </Button> */}
              {/* </Box> */}
            </Stack>
          ) : (
            <RegForm
              fields={data.reg.fields}
              onSubmit={(res) => setResponse(res)}
              eventId={data.reg.eventId}
              role={data.reg.role}
              user={
                userDetailData
                  ? JSON.parse(userDetailData?.getUserDetail)
                  : null
              }
            />
          )}
        </Box>
        <Box
          sx={{
            py: 6,
            display: "flex",
            justifyContent: { xs: "center", md: "right" },
          }}
        >
          <Box>
            <Typography color="text.secondary" fontSize={12}>
              Powered by
            </Typography>
            <a href="http://eventegration.com">
              <img src={LogoPng} width={180} />
            </a>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export function RegForm({ fields, onSubmit, eventId, role, user }) {
  //   console.log(fields.reduce((a, r) => ({ ...a, [r.name]: "" }), {}));
  const [openModal, setOpenModal] = React.useState(false);

  const id = user?.id;
  console.log(user?.status);

  const formDefaultValues = fields.reduce((acc, field) => {
    acc[field.name] =
      user && user.hasOwnProperty(field.name)
        ? user[field.name]
        : field.defaultValue;
    return acc;
  }, {});

  // console.log(formDefaultValues);
  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const { link } = useParams();

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: formDefaultValues
      ? formDefaultValues
      : fields.reduce((acc, field) => {
          const fieldValue = user?.[field.name] ?? field.defaultValue;
          // console.log(fieldValue);

          return {
            ...acc,
            [field.name]: fieldValue,
          };
        }, user),
    resolver: yupResolver(
      yup.object().shape({
        ...fields.reduce(
          (a, r) => ({ ...a, [r.name]: buildValidation(r) }),
          {}
        ),
        ...fields
          .filter((r) => r.options?.some((op) => op === "$Other"))
          ?.reduce(
            (a, r) => ({
              ...a,
              [r.name + "_other"]: yup
                .string()
                .min(r.minLength, "Too long")
                .max(r.maxLength, "Too long")
                // .matches(r.pattern, "Invalid pattern")
                .test({
                  message: "Required",
                  test: (a, p) =>
                    p.parent[r.name]?.includes("$Other") ? !!a : true,
                }),
            }),
            {}
          ),
      })
    ),
  });

  const [updateRegister, { loading: registerLoading, error: registerError }] =
    useMutation(UPDATE);
  const findCountryCodeByDialCode = (dialCode) => {
    const country = countries.find((c) => c.dial_code === dialCode);
    return country ? country.code.toLowerCase() : "om";
  };
  // console.log(`https://eventegration.com/${link}`);
  if (user?.status === "accept") {
    window.location.href = `https://eventegration.com/${link}`;
    // Optionally, you can return null or an empty div if you want to render nothing
    return null;
  }

  // if (user?.status === "accept"){
  // return(
  //   <div style={{ display: 'flex', alignItems: 'center' }}>
  //   <CheckCircleIcon style={{ color: 'green', marginRight: '10px' }} />
  //   <Typography variant="body1">Your confirmation request has been received. Thank you for your response!</Typography>
  // </div>)
  // }
  return (
    <>
      {/* <SearchModal
        eventId={eventId}
        role={role}
        open={openModal}
        onClose={handleModalClose}
      /> */}
      {/* <Stack
        direction="row"
        justifyContent={"center"}
        spacing={2}
        alignItems="center"
        p={2}
      >
        <Typography
          variant="body1"
          onClick={handleModalOpen}
          textAlign={"center"}
        >
          If you have previously registered, <Typography variant="caption"
            onClick={handleModalOpen}
            sx={{ color: "blueviolet", fontSize: 18, cursor: "pointer" }}>click here </Typography>to retrieve your badge.
        </Typography>
      </Stack> */}
      <form
        noValidate
        onSubmit={handleSubmit(async (f) => {
          const v = {};
          for (const r of fields) {
            if (r.options?.some((op) => op === "$Other")) {
              if (["select", "radio", "payment"].includes(r.type)) {
                if (f[r.name] === "$Other") {
                  v[r.name] = "Other: " + (f[r.name + "_other"] || "");
                  continue;
                }
              } else if (typeof f[r.name] === "object") {
                if (f[r.name]?.includes("$Other")) {
                  v[r.name] = [
                    ...(f[r.name]?.filter((o) => o !== "$Other") || []),
                    "Other: " + (f[r.name + "_other"] || ""),
                  ]
                    .filter((a) => !!a?.trim())
                    .join(", ");
                  continue;
                } else {
                  v[r.name] = (f[r.name] || [])
                    .filter((a) => !!a?.trim())
                    .join(", ");
                  continue;
                }
              }
            } else if (typeof f[r.name] === "object") {
              v[r.name] = f[r.name].join(", ");
            } else {
              v[r.name] = f[r.name];
            }
          }
          const result = await updateRegister({
            variables: {
              link,
              jsonData: JSON.stringify({ id, status: "accept", ...v }),
            },
          })
            .then((res) => res.data?.updateRegister)
            .catch((e) => false);
          return onSubmit(result);
        })}
      >
        <Grid container spacing={2}>
          {fields
            ?.map((f) => ({
              ...f,
              sx: f.sx ? JSON.parse(f.sx) : {},
            }))
            ?.map((f) => (
              <React.Fragment key={f.name}>
                <Grid
                  item
                  xs={["mcq", "radio", "payment"].includes(f.type) ? 12 : f.xs}
                  md={["mcq", "radio", "payment"].includes(f.type) ? 12 : f.md}
                >
                  <Controller
                    name={f.name}
                    control={control}
                    render={(rhf) => (
                      <MyField
                        rhf={rhf}
                        f={f}
                        setValue={setValue}
                        defaultValue={user?.[f.name]}
                        findCountryCodeByDialCode={findCountryCodeByDialCode}
                      />
                    )}
                  />
                </Grid>
                {f.options?.some((op) => op === "$Other") &&
                  watch(f.name)?.includes("$Other") && (
                    <Grid item xs={f.xs} md={f.md}>
                      <Controller
                        name={f.name + "_other"}
                        control={control}
                        rules={{
                          required: f.required ? "Required" : false,
                          maxLength: f.maxLength,
                          minLength: f.minLength,
                        }}
                        render={(rhf) => (
                          <MyField
                            rhf={rhf}
                            f={{
                              type: "text",
                              name: "Enter value",
                              placeholder: "Custom value",
                              required: f.required,
                              sx: {},
                            }}
                            defaultValue={user?.[f.name]}
                            setValue={setValue}
                            findCountryCodeByDialCode={
                              findCountryCodeByDialCode
                            }
                          />
                        )}
                      />
                    </Grid>
                  )}
              </React.Fragment>
            ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {registerError && (
            <Grid item xs={12}>
              <Typography color="error">{registerError?.message}</Typography>
            </Grid>
          )}
          {registerLoading && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent={"center"}
              spacing={2}
              alignItems="center"
            >
              <LoadingButton
                loading={registerLoading}
                type="submit"
                size="large"
                variant="contained"
                sx={{ width: { xs: "100%", md: 220 } }}
                endIcon={<AppRegistrationIcon />}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function MyField({
  rhf,
  f,
  setValue,
  findCountryCodeByDialCode,
  defaultValue,
}) {
  const errorMessage = rhf.fieldState.error?.message;

  React.useEffect(() => {
    setValue(rhf.field.name, defaultValue);
  }, [setValue, rhf.field.name, defaultValue]);
  // console.log(defaultValue);

  if (f.type === "mcq") {
    return (
      <FormControl
        fullWidth
        required={f.required}
        sx={f.sx || {}}
        error={!!rhf.fieldState.error}
      >
        <FormLabel>{f.label || f.name}</FormLabel>
        <FormGroup>
          <Grid container spacing={1}>
            {f.options?.map((op, i) => {
              const isChecked = rhf.field.value?.includes(op);

              const handleChange = (event) => {
                let updatedValue = [...(rhf.field.value || [])];
                if (event.target.checked) {
                  if (!updatedValue.includes(op)) {
                    updatedValue.push(op);
                  }
                } else {
                  updatedValue = updatedValue.filter((item) => item !== op);
                }
                setValue(rhf.field.name, updatedValue);
              };

              return (
                <Grid item xs={f.xs || 12} md={f.md || 6} key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={op}
                        checked={isChecked}
                        onChange={handleChange}
                        // checked={defaultValue === 'true' || defaultValue === true || defaultValue === '1' ? true : rhf.field.value}
                        // onChange={rhf.field.onChange}
                      />
                    }
                    label={
                      op === "$Other" ? (
                        "Other:"
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: op }} />
                      )
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </FormGroup>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    );
  } else if (f.type === "radio" || f.type === "payment") {
    return (
      <FormControl
        fullWidth
        required={f.required}
        sx={f.sx || {}}
        error={!!rhf.fieldState.error}
      >
        <FormLabel>{f.label || f.name}</FormLabel>
        <RadioGroup
          name={f.name}
          onChange={rhf.field.onChange}
          onBlur={rhf.field.onBlur}
          value={rhf.field.value}
        >
          <Grid container spacing={1}>
            {f.options?.map((op, i) => (
              <Grid item xs={f.xs || 12} md={f.md || 6} key={i}>
                <FormControlLabel
                  control={<Radio />}
                  label={op === "$Other" ? "Other:" : op}
                  value={op}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    );
  } else if (f.type === "checkbox") {
    return (
      <FormControl
        required={f.required}
        fullWidth
        sx={f.sx || {}}
        error={!!rhf.fieldState.error}
      >
        <FormControlLabel
          onChange={rhf.field.onChange}
          onBlur={rhf.field.onBlur}
          inputRef={rhf.field.ref}
          control={<Checkbox />}
          label={f.label || f.name}
          name={rhf.field.name}
          checked={rhf.field.value}
        />
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    );
  } else if (f.type === "select") {
    return (
      <TextField
        name={rhf.field.name}
        value={rhf.field.value}
        onChange={rhf.field.onChange}
        onBlur={rhf.field.onBlur}
        inputRef={rhf.field.ref}
        error={!!rhf.fieldState.error}
        helperText={errorMessage}
        label={f.label || f.name}
        required={f.required}
        sx={f.sx || {}}
        fullWidth
        select
      >
        <MenuItem value="">None</MenuItem>
        {f.options.map((op) => (
          <MenuItem key={op} value={op}>
            {op === "$Other" ? "Other:" : op}
          </MenuItem>
        ))}
      </TextField>
    );
  } else if (f.type === "country") {
    // console.log(rhf.field.value,"????????");
    return (
      <Autocomplete
        options={countries}
        autoHighlight
        value={
          !rhf.field.value
            ? countries.find((c) => c.code === "OM")
            : countries.find((c) => c.code === rhf.field.value)
        }
        onChange={(e, v) => {
          rhf.field.onChange(v?.code || "");
          setValue("calling_code", v?.dial_code || "");
        }}
        onBlur={rhf.field.onBlur}
        getOptionLabel={(o) => o?.label || ""}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.label} ({option.code}) {option.dial_code}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
            name={rhf.field.name}
            inputRef={rhf.field.ref}
            error={!!rhf.fieldState.error}
            helperText={errorMessage}
            label={f.label || f.name}
            placeholder={f.placeholder}
            required={f.required}
            sx={f.sx || {}}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${
                      rhf.field.value.toLowerCase() || "om"
                    }.png`}
                    alt="country_flag"
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  } else if (f.type === "mobile_code") {
    return (
      <Autocomplete
        options={countries}
        autoHighlight
        value={
          !rhf.field.value
            ? countries.find((c) => c.dial_code === "+968")
            : countries.find((c) => c.dial_code === rhf.field.value)
        }
        onChange={(e, v) => {
          rhf.field.onChange(v?.dial_code || "");
        }}
        onBlur={rhf.field.onBlur}
        getOptionLabel={(o) => o?.dial_code || ""}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="30"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.dial_code}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
            name={rhf.field.name}
            inputRef={rhf.field.ref}
            error={!!rhf.fieldState.error}
            helperText={errorMessage}
            label={f.label || f.name}
            placeholder={f.placeholder}
            required={f.required}
            sx={f.sx || {}}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${findCountryCodeByDialCode(
                      rhf.field.value
                    )}.png`}
                    alt="country_flag"
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  } else if (f.type === "email" || f.type === "confirm_email") {
    return (
      <TextField
        type={f.type === "email" ? "email" : "confirm_email"}
        InputProps={{
          autoComplete:
            f.autoComplete || f.type === "confirm_email"
              ? "new-password"
              : "on",
          readOnly: f.readonly,
        }}
        name={rhf.field.name}
        value={rhf.field.value}
        onChange={rhf.field.onChange}
        onBlur={rhf.field.onBlur}
        inputRef={rhf.field.ref}
        error={!!rhf.fieldState.error}
        helperText={errorMessage}
        label={f.label || f.name}
        placeholder={f.placeholder}
        required={f.required}
        readonly={f.readonly}
        sx={f.sx || {}}
        fullWidth
      />
    );
  }
  return (
    <TextField
      type={f.type === "number" ? "number" : "text"}
      InputProps={{
        autoComplete:
          f.autoComplete || f.type === "confirm_email" ? "new-password" : "on",
        readOnly: f.readonly,
      }}
      name={rhf.field.name}
      value={rhf.field.value}
      onChange={rhf.field.onChange}
      onBlur={rhf.field.onBlur}
      inputRef={rhf.field.ref}
      error={!!rhf.fieldState.error}
      helperText={errorMessage}
      label={f.label || f.name}
      placeholder={f.placeholder}
      required={f.required}
      readonly={f.readonly}
      sx={f.sx || {}}
      fullWidth
    />
  );
}

const buildValidation = (f) => {
  let v = yup;
  if (f.type === "checkbox") {
    v = v.boolean();
  } else if (f.type === "select") {
    v = v.string();
  } else if (f.type === "mcq") {
    if (f.required) {
      return v.mixed().test({
        message: "Required",
        test: (v) => {
          return v?.length > 0;
        },
      });
    } else {
      return v.array().of(yup.string());
    }
  } else if (f.type === "radio" || f.type === "payment") {
    v = v.string();
  } else if (f.type === "number") {
    v = v.number();
  } else if (f.type === "email") {
    v = v.string().trim().email();
  } else if (f.type === "confirm_email") {
    v = v
      .string()
      .trim()
      .test({
        message: "Does not match",
        test: (val, p) => {
          if ("Email Address" in p.parent) {
            return val === p.parent["Email Address"];
          }
          return val === p.parent["Email"];
        },
      });
  } else {
    v = v.string().trim();
  }

  if (f.required) {
    v = v.required("Required");
  }
  if (
    f.maxLength &&
    !["checkbox", "radio", "payment", "select", "mcq"].includes(f.type)
  ) {
    v = v.max(
      f.maxLength,
      f.type === "number"
        ? `Max value: ${f.maxLength}`
        : `Max ${f.maxLength} characters allowed`
    );
  }
  if (
    f.minLength &&
    !["checkbox", "radio", "select", "mcq", "payment"].includes(f.type)
  ) {
    v = v.min(
      f.minLength,
      f.type === "number"
        ? `Min value: ${f.minLength}`
        : `Min ${f.minLength} characters required`
    );
  }
  if (f.pattern) {
    v = v.matches(f.pattern, "Invalid pattern!");
  }
  return v;
};

const UPDATE = gql`
  mutation ($link: String!, $jsonData: String!) {
    updateRegister(link: $link, jsonData: $jsonData)
  }
`;
const GET_REG_STRUCTURE = gql`
  query ($link: String!) {
    reg(link: $link) {
      eventId
      eventName
      role
      formHeading
      shareContent
      fields {
        name
        type
        label
        sx
        xs
        md
        placeholder
        options
        defaultValue
        minLength
        maxLength
        pattern
        required
        readonly
      }
    }
  }
`;
const GET_USER_DETAIL = gql`
  query ($id: String!, $role: ROLE!, $eventId: String!) {
    getUserDetail(id: $id, role: $role, eventId: $eventId)
  }
`;
